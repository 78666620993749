import React, { useState } from "react";

const About = () => {
  const [activeTab, setActiveTab] = useState("features-tab-1");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  return (
    <>
      <section id="about" className="about section">
        {/* Section Title */}
        <div className="container section-title" data-aos="fade-up">
          <h2>About Us</h2>
        </div>

        <div className="container" data-aos="fade-up">
          <div
            className="row g-4 g-lg-5"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="col-lg-5">
              <div className="about-img">
                <img
                  src="assets/img/team/team-1.jpg"
                  className="img-fluid"
                  alt="About"
                />
              </div>
            </div>

            <div className="col-lg-7" style={{ marginTop: "5rem" }}>
              <p style={{ textAlign: "justify" }}>
                At MK Services, we pride ourselves on being a trusted bridge
                between businesses and clients, fostering seamless connections
                that drive growth and mutual success. Whether you're a service
                provider or a product-based business, our platform is designed
                to cater to your unique needs. We empower businesses across all
                sectors by providing a robust and reliable B2C
                (Business-to-Consumer) platform where entrepreneurs and
                customers can easily engage, transact, and build lasting
                relationships.
              </p>

              <p style={{ textAlign: "justify" }}>
                Our online presence amplifies your brand’s reach, helping you
                promote your products or services to a wider audience with ease.
                By leveraging innovative solutions and a user-friendly
                interface, MK Services simplifies marketing efforts and ensures
                smooth client interactions. Our mission is to help businesses
                thrive while offering clients a reliable space to discover and
                connect with trusted providers. Join us in transforming the way
                businesses and clients interact in Karnataka and beyond.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
