import React from "react";

const Team = () => {
  return (
    <section id="team" className="team section">
      {/* Section Title */}
      <div className="container section-title" data-aos="fade-up">
        <h2>Our Team</h2>
      </div>

      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div
          className="row gy-5"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            className="col-xl-5 col-md-4 d-flex"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <div className="team-member">
              <div className="member-img">
                <img
                  src="assets/img/team/team-1.jpg"
                  className="img-fluid"
                  alt="Walter White"
                />
              </div>
              <div className="member-info">
                <h4>Anuradha Umesh Jain</h4>
                <span>Proprieter</span>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <h3 className="pt-0 pt-lg-5">Anuradha Umesh Jain</h3>
            <p style={{ textAlign: "justify" }}>
              Proprietor Anuradha Umesh Jain Education M.com I have studied I am
              working from home as a housewife I am running a small wholesale
              clothing store Rajeshwari Fashion House Showroom I started on a
              small scale Many people are doing business in our Karnataka but
              they are not getting an easy way to do marketing, To do business
              in an easy way MK Services Purpose Our MK Services purpose is to
              give all kinds of advice to the public It provides employment to
              many and helps some to do business Our main purpose is to
              introduce distant customers to businessmen and how many people are
              not able to do marketing in their business Our MK Services will
              help businessmen to increase their business easily Everyone use MK
              Services Increase your business income
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
