import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Header.css"; // Ensure to import the CSS file

const Header = () => {
  const location = useLocation(); // Get current route

  const [isMobile, setIsMobile] = useState(false); // Mobile state
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Menu state for mobile view

  // Check if the screen size is mobile
  const checkIfMobile = () => {
    setIsMobile(window.innerWidth <= 768); // You can adjust the width for mobile view
  };

  // Update isMobile state on window resize
  useEffect(() => {
    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);
    return () => window.removeEventListener("resize", checkIfMobile); // Cleanup event listener
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle the menu state
  };

  return (
    <header id="header" className="header d-flex align-items-center sticky-top">
      <div className="container-fluid position-relative d-flex align-items-center justify-content-between">
        <div style={{ marginLeft: "1rem" }}>
          <a href="/">
            <img style={{ width: "53px" }} src="assets/img/logo.png" alt="Logo" />
          </a>
        </div>

        {isMobile ? (
          <>
            {/* Mobile Menu Toggle Button */}
            <i
              className="mobile-nav-toggle bi bi-list"
              onClick={toggleMenu} // Toggle menu on click
            ></i>

            {/* Overlay Background */}
            {isMenuOpen && <div className="mobile-nav-overlay" onClick={toggleMenu}></div>}

            {/* Mobile Menu */}
            <nav className={`mobile-menu ${isMenuOpen ? "open" : ""}`}>
              <ul>
                <li>
                  <Link
                    to="/"
                    className={location.pathname === "/" ? "active" : ""}
                    onClick={toggleMenu}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about"
                    className={location.pathname === "/about" ? "active" : ""}
                    onClick={toggleMenu}
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services"
                    className={
                      location.pathname === "/services" ||
                      location.pathname === "/service-list"
                        ? "active"
                        : ""
                    }
                    onClick={toggleMenu}
                  >
                    Services
                  </Link>
                </li>
                <li>
                  <Link
                    to="/team"
                    className={location.pathname === "/team" ? "active" : ""}
                    onClick={toggleMenu}
                  >
                    Team
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    className={location.pathname === "/contact" ? "active" : ""}
                    onClick={toggleMenu}
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </nav>
          </>
        ) : (
          <nav id="navmenu" className="navmenu">
            <ul>
              <li>
                <Link to="/" className={location.pathname === "/" ? "active" : ""}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about" className={location.pathname === "/about" ? "active" : ""}>
                  About
                </Link>
              </li>
              <li>
                <Link
                  to="/services"
                  className={
                    location.pathname === "/services" || location.pathname === "/service-list"
                      ? "active"
                      : ""
                  }
                >
                  Services
                </Link>
              </li>
              <li>
                <Link to="/team" className={location.pathname === "/team" ? "active" : ""}>
                  Team
                </Link>
              </li>
              <li>
                <Link to="/contact" className={location.pathname === "/contact" ? "active" : ""}>
                  Contact
                </Link>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header;
