import React from "react";

const Footer = () => {
  return (
    <footer id="footer" className="footer dark-background">
      <div className="footer-top">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-6 col-md-6 footer-about">
              <a href="index.html" className="logo d-flex align-items-center">
                <span className="sitename">MK Services</span>
              </a>
              <div className="footer-contact pt-3">
                <p>A108 Adam Street</p>
                <p>New York, NY 535022</p>
                <p className="mt-3">
                  <strong>Phone:</strong> <span>+91 8105590353</span>
                </p>
                <p>
                  <strong>Email:</strong> <span>mkservices1919@gmail.com</span>
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-4 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/about">About us</a>
                </li>
                <li>
                  <a href="/services">Services</a>
                </li>
                <li>
                  <a href="/team">Team</a>
                </li>
                <li>
                  <a href="/contact">Contact</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="copyright text-center">
        <div style={{ display: "flex", justifyContent: "center" }}>
          Copyright © All rights reserved | 2024© Design & Develop by &nbsp;
          <a href="https://c2m-edutech.com/" target="blank">
            C2M Edutech Solutions Ltd
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
